#topRow {
  text-align: center;
  align-items: center;
  height: 10vmin;
  min-height: 50px;
}

#bottomRow {
  padding-top: 10px;
  border-radius: 5px;
  background-color: rgb(250, 250, 249);
}

#statusMessage {
  position: absolute;
}

input + label {
  color: gray !important;
}

td {
  text-align: left;
}

#cardCol {
  float: none;
  margin: 0 auto;
}

#infoCard {
  width: 100%;
  margin: 0 auto;
  float: none;
}
