#button {
  background-color: var(--bright-blue);
  border-color: var(--bright-blue);
  font-size: 16px;
  width: 100px;
  padding: 0.2rem;
}

#button:hover {
  background-color: var(--button-hover-blue);
  border-color: var(--button-hover-blue);
}

#button:active {
  background-color: var(--button-active-blue);
  border-color: var(--button-active-blue);
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  #button {
    font-size: 16px;
    width: 80px;
    padding: 0.2rem;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  #button {
    font-size: 16px;
    width: 100px;
    padding: 0.2rem;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #button {
    font-size: 16px;
    width: 140px;
    padding: 0.2rem;
  }
}
