#button {
  background-color: var(--purple);
  border-color: var(--purple);
  font-size: 16px;
  width: 100px;
  padding: 0.2rem;
}

#button:hover {
  background-color: var(--button-hover-purple);
  border-color: var(--button-hover-purple);
}

#button:active {
  background-color: var(--button-active-purple);
  border-color: var(--button-active-purple);
}

/*  Tablet */
@media (min-width: 768px) {
  #button {
    font-size: 16px;
    width: 100px;
    padding: 0.2rem;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #button {
    font-size: 16px;
    width: 140px;
    padding: 0.2rem;
  }
}

