#topRow {
  height: 75vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 520px;
}

#bottomRow {
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: top;
  margin-left: 0;
  margin-right: 0;
  margin-top: 80px;
}

#detailCol {
  margin: 0;
  padding: 0;
}

#collage {
  margin-top: 0;
  max-width: 80%;
  height: auto;
  width: auto\9;
  display: inline-block;
}

#collageCol {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  max-width: 600px;
}

#textCol {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  max-width: 350px;
}

#buttons {
  display: flex; /* establish flex container */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  margin: auto;
}

#header {
  margin: 0;
  padding: 0;
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  #topRow {
    min-height: 600px;
  }
  #collage {
    max-width: 100%;
  }
  #buttons {
    text-align: left !important;
    margin: unset;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  #topRow {
    min-height: 350px;
    margin-top: 0;
  }
  #bottomRow {
    margin-top: 0;
  }
  #textCol {
    text-align: left;
    max-width: 500px;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #topRow {
    min-height: 550px;
  }
  #textCol {
    text-align: left;
  }
  #buttons {
    text-align: center;
  }
}
