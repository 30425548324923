#topRow {
  text-align: center;
  align-items: center;
  height: 10vmin;
  min-height: 50px;
}

#headerImage {
  margin-top: 0;
  max-width: 100%;
  height: auto;
  width: auto\9;
  display: inline-block;
}

#pageNavContainer {
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

#pageNavLink {
  text-align: center;
}

#pageNavUnderline {
  border-top: 1px solid black;
}

#missionImage {
  max-width: 100%;
  max-height: 800px;
  height: auto;
  max-height: 100%;
  border-radius: 15px;
}

#missionImageCard {
  width: 100%;
  max-height: min(70vh, 1000px);
  min-height: 200px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

#missionImageContent {
  width: 100%;
  padding: 20px 40px;
  text-align: center;
}

#missionImageContent:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.parallax {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-perspective: 1px;
  perspective: 1px;
}

#missionImageParallaxLayer {
  padding: 50vh 0;
}

.parallax__layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.parallax__layer_base {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.parallax__layer_back {
  -webkit-transform: translateZ(-1px) scale(2);
  transform: translateZ(-1px) scale(2);
}

/* centre the content in the parallax layers */
.center_parallax {
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.parallax__group {
  position: relative;
  height: 100vh;
  transform-style: preserve-3d;
}

#firstInfoPane {
  height: 500px;
  background-color: aqua;
}

#missionImageParallaxGroup {
  height: 100vh;
}

.card {
  width: 100%;
  height: 70vh;
  max-height: 1000px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

.card_content {
  position: relative;
  width: 80%;
  padding: 20px 40px;
  overflow: scroll;
}

.card_title {
  /* display: table; */
  /* vertical-align: middle; */
  align-items: center;
  height: 10vh;
  padding: 20px 40px;
  margin: 0;
  width: 100%;
  background-color: var(--soft-blue);
}

.card_image {
  width: 20%;
  max-width: 100%;
  text-align: center;
  display: inline-block;
  background-color: var(--soft-blue);
  overflow: hidden;
}

.card_image:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.pencil_container {
  max-width: 60%;
  max-height: 90%;
}

.pencil {
  max-width: 30%;
  max-height: 90%;
}

@media (min-height: 768px) {
  .card {
    height: 70vh;
  }
}

#gettingWorkCol {
  text-align: center;
  padding-left: 25px;
}

#gettingWorkImageDesktop {
  width: 100%;
  display: none;
  border-radius: 10px;
}

#gettingWorkImageMobile {
  width: 70%;
  display: inline;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Desktop */
@media (min-width: 768px) {
  .center_parallax {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .card_content {
    width: 100%;
  }
  .pencil_container {
    max-width: 50%;
    max-height: 90%;
  }
  #gettingWorkImageDesktop {
    display: inline;
  }

  #gettingWorkImageMobile {
    display: none;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .center_parallax {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
