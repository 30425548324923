@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Georgia');

:root {
  --dark-blue: #0a2463;
  --bright-blue: #5271ff;
  --soft-blue: #d4dcfd;
  --white: #ffffff;
  --warm-white: #fff9e8;
  --yellow: #f9e54d;
  --orange: #f37748;
  --turqoise: #66c7f4;
  --soft-green: rgb(219, 248, 222);
  --dark-grey: #333333;
  --purple: #b496d9;
  --button-hover-purple: #a785d1;
  --button-active-purple: #9879be;
  --button-hover-blue: #4964db;
  --button-active-blue: #415ace;
}

* {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Georgia', serif;
}

h1 {
  font-size: 28px !important;
}
h2 {
  font-size: 22px !important;
}
h3 {
  font-size: 20px !important;
}
h4 {
  font-size: 18px !important;
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  h4 {
    font-size: 18px !important;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 26px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 20px !important;
  }
}

/* Desktop */
@media (min-width: 992px) {
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 32px !important;
  }
  h3 {
    font-size: 26px !important;
  }
  h4 {
    font-size: 22px !important;
  }
}
