@import url('./App.css');

.navbar-dark {
  background-color: var(--bright-blue);
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--white);
  padding-right: 2em;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: var(--yellow);
  text-underline-offset: 4px;
  text-decoration: underline;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--yellow);
}

.navbar-dark .dropdown-menu {
  background-color: var(--bright-blue);
}

.navbar-dark .dropdown-item {
  background-color: var(--bright-blue);
  color: white;
}

.navbar-dark .dropdown-item:hover {
  color: var(--yellow);
}

.navbar-dark .dropdown-item.active {
  color: var(--yellow);
  text-underline-offset: 4px;
  text-decoration: underline;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler-icon:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28249,229,77' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
