@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Georgia);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-blue: #0a2463;
  --bright-blue: #5271ff;
  --soft-blue: #d4dcfd;
  --white: #ffffff;
  --warm-white: #fff9e8;
  --yellow: #f9e54d;
  --orange: #f37748;
  --turqoise: #66c7f4;
  --soft-green: rgb(219, 248, 222);
  --dark-grey: #333333;
  --purple: #b496d9;
  --button-hover-purple: #a785d1;
  --button-active-purple: #9879be;
  --button-hover-blue: #4964db;
  --button-active-blue: #415ace;
}

* {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Georgia', serif;
}

h1 {
  font-size: 28px !important;
}
h2 {
  font-size: 22px !important;
}
h3 {
  font-size: 20px !important;
}
h4 {
  font-size: 18px !important;
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  h1 {
    font-size: 28px !important;
  }
  h2 {
    font-size: 24px !important;
  }
  h3 {
    font-size: 22px !important;
  }
  h4 {
    font-size: 18px !important;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  h1 {
    font-size: 32px !important;
  }
  h2 {
    font-size: 26px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 20px !important;
  }
}

/* Desktop */
@media (min-width: 992px) {
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 32px !important;
  }
  h3 {
    font-size: 26px !important;
  }
  h4 {
    font-size: 22px !important;
  }
}

.navbar-dark {
  background-color: var(--bright-blue);
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--white);
  padding-right: 2em;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: var(--yellow);
  text-underline-offset: 4px;
  text-decoration: underline;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--yellow);
}

.navbar-dark .dropdown-menu {
  background-color: var(--bright-blue);
}

.navbar-dark .dropdown-item {
  background-color: var(--bright-blue);
  color: white;
}

.navbar-dark .dropdown-item:hover {
  color: var(--yellow);
}

.navbar-dark .dropdown-item.active {
  color: var(--yellow);
  text-underline-offset: 4px;
  text-decoration: underline;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler-icon:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28249,229,77' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

#Home_topRow__3g6f3 {
  height: 75vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 520px;
}

#Home_bottomRow___ENG- {
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: top;
  margin-left: 0;
  margin-right: 0;
  margin-top: 80px;
}

#Home_detailCol__2oH0- {
  margin: 0;
  padding: 0;
}

#Home_collage__1Wgur {
  margin-top: 0;
  max-width: 80%;
  height: auto;
  width: auto\9;
  display: inline-block;
}

#Home_collageCol__rVuJy {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  max-width: 600px;
}

#Home_textCol__1TnN2 {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  max-width: 350px;
}

#Home_buttons__UDPkF {
  display: flex; /* establish flex container */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  margin: auto;
}

#Home_header__3Q40q {
  margin: 0;
  padding: 0;
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  #Home_topRow__3g6f3 {
    min-height: 600px;
  }
  #Home_collage__1Wgur {
    max-width: 100%;
  }
  #Home_buttons__UDPkF {
    text-align: left !important;
    margin: unset;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  #Home_topRow__3g6f3 {
    min-height: 350px;
    margin-top: 0;
  }
  #Home_bottomRow___ENG- {
    margin-top: 0;
  }
  #Home_textCol__1TnN2 {
    text-align: left;
    max-width: 500px;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #Home_topRow__3g6f3 {
    min-height: 550px;
  }
  #Home_textCol__1TnN2 {
    text-align: left;
  }
  #Home_buttons__UDPkF {
    text-align: center;
  }
}

#PrimaryButton_button__48M2W {
  background-color: var(--purple);
  border-color: var(--purple);
  font-size: 16px;
  width: 100px;
  padding: 0.2rem;
}

#PrimaryButton_button__48M2W:hover {
  background-color: var(--button-hover-purple);
  border-color: var(--button-hover-purple);
}

#PrimaryButton_button__48M2W:active {
  background-color: var(--button-active-purple);
  border-color: var(--button-active-purple);
}

/*  Tablet */
@media (min-width: 768px) {
  #PrimaryButton_button__48M2W {
    font-size: 16px;
    width: 100px;
    padding: 0.2rem;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #PrimaryButton_button__48M2W {
    font-size: 16px;
    width: 140px;
    padding: 0.2rem;
  }
}


#About_topRow__RGKrw {
  text-align: center;
  align-items: center;
  height: 10vmin;
  min-height: 50px;
}

#About_headerImage__yg16M {
  margin-top: 0;
  max-width: 100%;
  height: auto;
  width: auto\9;
  display: inline-block;
}

#About_pageNavContainer__3JP_M {
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

#About_pageNavLink__wJp1R {
  text-align: center;
}

#About_pageNavUnderline__1Lwo3 {
  border-top: 1px solid black;
}

#About_missionImage__2bBWC {
  max-width: 100%;
  max-height: 800px;
  height: auto;
  max-height: 100%;
  border-radius: 15px;
}

#About_missionImageCard__1cOpS {
  width: 100%;
  max-height: min(70vh, 1000px);
  min-height: 200px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

#About_missionImageContent__2ADhj {
  width: 100%;
  padding: 20px 40px;
  text-align: center;
}

#About_missionImageContent__2ADhj:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.About_parallax__1q1ps {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-perspective: 1px;
  perspective: 1px;
}

#About_missionImageParallaxLayer__3zgY1 {
  padding: 50vh 0;
}

.About_parallax__layer__8XIUO {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.About_parallax__layer_base__37RfD {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.About_parallax__layer_back__1uILK {
  -webkit-transform: translateZ(-1px) scale(2);
  transform: translateZ(-1px) scale(2);
}

/* centre the content in the parallax layers */
.About_center_parallax__4pWnN {
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.About_parallax__group__ZiR-K {
  position: relative;
  height: 100vh;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

#About_firstInfoPane__1myg0 {
  height: 500px;
  background-color: aqua;
}

#About_missionImageParallaxGroup__3578I {
  height: 100vh;
}

.About_card__KukzU {
  width: 100%;
  height: 70vh;
  max-height: 1000px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  overflow: hidden;
}

.About_card_content__1riQE {
  position: relative;
  width: 80%;
  padding: 20px 40px;
  overflow: scroll;
}

.About_card_title__2A8gm {
  /* display: table; */
  /* vertical-align: middle; */
  align-items: center;
  height: 10vh;
  padding: 20px 40px;
  margin: 0;
  width: 100%;
  background-color: var(--soft-blue);
}

.About_card_image__333ed {
  width: 20%;
  max-width: 100%;
  text-align: center;
  display: inline-block;
  background-color: var(--soft-blue);
  overflow: hidden;
}

.About_card_image__333ed:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.About_pencil_container__LXsFC {
  max-width: 60%;
  max-height: 90%;
}

.About_pencil__1FnDW {
  max-width: 30%;
  max-height: 90%;
}

@media (min-height: 768px) {
  .About_card__KukzU {
    height: 70vh;
  }
}

#About_gettingWorkCol__uP7Ha {
  text-align: center;
  padding-left: 25px;
}

#About_gettingWorkImageDesktop__39b1l {
  width: 100%;
  display: none;
  border-radius: 10px;
}

#About_gettingWorkImageMobile__lr9Yq {
  width: 70%;
  display: inline;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Desktop */
@media (min-width: 768px) {
  .About_center_parallax__4pWnN {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .About_card_content__1riQE {
    width: 100%;
  }
  .About_pencil_container__LXsFC {
    max-width: 50%;
    max-height: 90%;
  }
  #About_gettingWorkImageDesktop__39b1l {
    display: inline;
  }

  #About_gettingWorkImageMobile__lr9Yq {
    display: none;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .About_center_parallax__4pWnN {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

#SecondaryButton_button__2tgnk {
  background-color: var(--bright-blue);
  border-color: var(--bright-blue);
  font-size: 16px;
  width: 100px;
  padding: 0.2rem;
}

#SecondaryButton_button__2tgnk:hover {
  background-color: var(--button-hover-blue);
  border-color: var(--button-hover-blue);
}

#SecondaryButton_button__2tgnk:active {
  background-color: var(--button-active-blue);
  border-color: var(--button-active-blue);
}

/*  Vertical tablet, horizontal phone */
@media (min-width: 576px) {
  #SecondaryButton_button__2tgnk {
    font-size: 16px;
    width: 80px;
    padding: 0.2rem;
  }
}

/*  Tablet */
@media (min-width: 768px) {
  #SecondaryButton_button__2tgnk {
    font-size: 16px;
    width: 100px;
    padding: 0.2rem;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #SecondaryButton_button__2tgnk {
    font-size: 16px;
    width: 140px;
    padding: 0.2rem;
  }
}

#Contact_topRow__1ttAG {
  text-align: center;
  align-items: center;
  height: 10vmin;
  min-height: 50px;
}

#Contact_bottomRow__2B8r8 {
  padding-top: 10px;
  border-radius: 5px;
  background-color: rgb(250, 250, 249);
}

#Contact_statusMessage__2J_iv {
  position: absolute;
}

input + label {
  color: gray !important;
}

td {
  text-align: left;
}

#Contact_cardCol__1EXCa {
  float: none;
  margin: 0 auto;
}

#Contact_infoCard__t7qry {
  width: 100%;
  margin: 0 auto;
  float: none;
}

